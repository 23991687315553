import "isomorphic-fetch";
import {MakeDto, ModelDto, StateDto, ColorDto, BodyDto} from "../../model/dto/refdata";
import {serverErrorHandler} from "./lib/ServerErrorHandler";
import {Dispatch} from "redux";

export type RefDataAction = StatesFetchBefore | StatesFetchAfter | StatesFetchError
    | BodiesFetchBefore | BodiesFetchAfter | BodiesFetchError
    | MakesFetchBefore | MakesFetchAfter | MakesFetchError
    | ModelsFetchBefore | ModelsFetchAfter | ModelsFetchError
    | ColorsFetchBefore | ColorsFetchAfter | ColorsFetchError

export class StatesFetchBefore {
    type: "StatesFetchBefore" = "StatesFetchBefore"
}

export class StatesFetchAfter {
    type: "StatesFetchAfter" = "StatesFetchAfter"

    constructor(public states: StateDto[]) {
    }
}

export class StatesFetchError {
    type: "StatesFetchError" = "StatesFetchError"

    constructor(public error: any) {
    }
}

export function statesFetch() {
    return refDataFetch(
        `/api/refdata/states`,
        new StatesFetchBefore(),
        (action, json) => {
            return new StatesFetchAfter(json)
        },
        (action, error) => {
            return new StatesFetchError(error)
        }
    )
}

export class BodiesFetchBefore {
    type: "BodiesFetchBefore" = "BodiesFetchBefore"

    constructor() {
    }
}

export class BodiesFetchAfter {
    type: "BodiesFetchAfter" = "BodiesFetchAfter"

    constructor(public bodies: BodyDto[]) {
    }
}

export class BodiesFetchError {
    type: "BodiesFetchError" = "BodiesFetchError"

    constructor(public error: any) {
    }
}

export function bodiesFetch() {
    return refDataFetch(
        `/api/refdata/bodies`,
        new BodiesFetchBefore(),
        (action, json) => {
            return new BodiesFetchAfter(json)
        },
        (action, error) => {
            return new BodiesFetchError(error)
        }
    )
}


export class MakesFetchBefore {
    type: "MakesFetchBefore" = "MakesFetchBefore"

    constructor() {
    }
}

export class MakesFetchAfter {
    type: "MakesFetchAfter" = "MakesFetchAfter"

    constructor(public makes: MakeDto[]) {
    }
}

export class MakesFetchError {
    type: "MakesFetchError" = "MakesFetchError"

    constructor(public error: any) {
    }
}

export function makesFetch() {
    return refDataFetch(
        `/api/refdata/makes`,
        new MakesFetchBefore(),
        (action, json) => {
            return new MakesFetchAfter(json)
        },
        (action, error) => {
            return new MakesFetchError(error)
        }
    )
}

export class ModelsFetchBefore {
    type: "ModelsFetchBefore" = "ModelsFetchBefore"

    constructor(public makeCode: string) {
    }
}

export class ModelsFetchAfter {
    type: "ModelsFetchAfter" = "ModelsFetchAfter"

    constructor(public makeCode: string, public models: ModelDto[]) {
    }
}

export class ModelsFetchError {
    type: "ModelsFetchError" = "ModelsFetchError"

    constructor(public makeCode: string, public error: any) {
    }
}

export function modelsFetch(makeCode: string) {
    return refDataFetch(
        `/api/refdata/models/by-make/${makeCode}`,
        new ModelsFetchBefore(makeCode),
        (action, json) => {
            return new ModelsFetchAfter(makeCode, json.map((b:any) => {
                return new ModelDto(b.name, b.makeId)
            }))
        },
        (action, error) => {
            return new ModelsFetchError(makeCode, error)
        }
    )
}


export class ColorsFetchBefore {
    type: "ColorsFetchBefore" = "ColorsFetchBefore"

    constructor() {
    }
}

export class ColorsFetchAfter {
    type: "ColorsFetchAfter" = "ColorsFetchAfter"

    constructor(public colors: ColorDto[]) {
    }
}

export class ColorsFetchError {
    type: "ColorsFetchError" = "ColorsFetchError"

    constructor(public error: any) {
    }
}

export function colorsFetch() {
    return refDataFetch(
        `/api/refdata/colors`,
        new ColorsFetchBefore(),
        (action, json) => {
            return new ColorsFetchAfter(json)
        },
        (action, error) => {
            return new ColorsFetchError(error)
        }
    )
}


function refDataFetch(url: string, action: RefDataAction, success: (action: RefDataAction, json:any) => RefDataAction, failure: (action: RefDataAction, error:any) => RefDataAction) {

    return function (dispatch: Dispatch<any>) {

        dispatch(action)

        return fetch(url)
            .then(serverErrorHandler)
            .then(response => response.json())
            .then(json => dispatch(success(action, json)))
            .catch((error) => {
                dispatch(failure(action, error))
            })

    }

}

