import {BodyDto, ColorDto, MakeDto, ModelDto, RegionDto, StateDto} from "./refdata";

export interface PpiCompanyDto {
    destination?: string
    latestRep?: string
    companyName?: string
    preferredCity?: string
    preferredStateCode?: string
    phoneNumber?: string;
}

export interface CodeAndName {
    code: string | number
    name: string
}

export interface PpiVehicleDto {

    make?: MakeDto
    model?: ModelDto
    color?: ColorDto
    body?: BodyDto
    license?: string
    licenseState?: StateDto
    noVin?: boolean
    noPlate?: boolean
    nonStandardVin?: boolean
    vin?: string
    year?: string
}

export interface PpiTowDto {
    region?: RegionDto;
    date?: string
    time?: string
    referenceNumber?: string
    origin?: string
    originCity?: string
    originState?: StateDto
    destination?: string
    destinationCity?: string
    destinationState?: StateDto
    propertyName?: string
    lawAgency?: string
    repo?: boolean
    towCompanyRep?: string
    driver?: string
    notes?: string
    towCompanyName?: string
    vehicleOwner?: string
    lienHolder?: string
    lienHolderPhone?: string
    requestedBy?: string
    driverId?: string
}

export class PpiSubmitDto {
    vehicle: PpiVehicleDto;
    tow: PpiTowDto;
}

export class PpiResultDto {
    submitDate: string;
    referenceNumber: string;
    ppiVehicleId: number;
    fmUrl: string;
}

export class DecodedVinDto {
    make?: MakeDto
    model?: ModelDto
    year?: string
    bodyDto: BodyDto
}

export class PpiFileSubmitDto {
    constructor(public ppiVehicleId: number,
                public attachmentIds: number[]) {
    }
}

export class PpiFileSubmitResultDto {
    constructor() {
    }
}

export class PpiVehicleDispositionRequestDto {
    referenceNum: string
    vin: string
    license: string
}

export class PpiVehicleDispositionInfoDto {
    showVehicleDisposition: boolean;
    regionCode: string;
    vehicleId: number;
}