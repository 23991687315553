import * as Redux from "redux";
import {
    MakesFetchAfter,
    ModelsFetchAfter,
    BodiesFetchAfter,
    StatesFetchAfter,
    RefDataAction,
    ColorsFetchAfter
} from "../actions/RefDataActions";
import {StateDto, MakeDto, ModelDto, ColorDto, BodyDto} from "../dto/refdata";

interface RefStatesState {
    values: StateDto[]
}

const states = (state: RefStatesState = {values: []}, action: RefDataAction): RefStatesState => {
    switch (action.type) {
        case "StatesFetchAfter":
            let typedAction = action as StatesFetchAfter // TODO: remove when intellij works correctly
            return {values: typedAction.states}
        default:
            return state
    }
}


interface RefBodiesState {
    values: BodyDto[]
}

const bodies = (body: RefBodiesState = {values: []}, action: RefDataAction): RefBodiesState => {
    switch (action.type) {
        case "BodiesFetchAfter":
            let typedAction = action as BodiesFetchAfter // TODO: remove when intellij works correctly
            return {values: typedAction.bodies}
        default:
            return body
    }
}


interface RefMakesState {
    values: MakeDto[]
}

const makes = (state: RefMakesState = {values: []}, action: RefDataAction): RefMakesState => {
    switch (action.type) {
        case "MakesFetchAfter":
            let typedAction = action as MakesFetchAfter // TODO: remove when intellij works correctly
            return {values: typedAction.makes}
        default:
            return state
    }
}

interface RefModelsMakeState {
    values: ModelDto[]
}

interface RefModelsState {
    [makeCode: string]: RefModelsMakeState
}

const models = (state: RefModelsState = {}, action: RefDataAction): RefModelsState => {
    switch (action.type) {
        case "ModelsFetchAfter":
            let typedAction = action as ModelsFetchAfter // TODO: remove when intellij works correctly

            let newState = <RefModelsState>{}
            newState[typedAction.makeCode] = {values: typedAction.models}
            return Object.assign({}, state, newState)
        default:
            return state
    }
}


interface RefColorsState {
    values: ColorDto[]
}

const colors = (state: RefColorsState = {values: []}, action: RefDataAction): RefColorsState => {
    switch (action.type) {
        case "ColorsFetchAfter":
            let typedAction = action as ColorsFetchAfter // TODO: remove when intellij works correctly
            return {values: typedAction.colors}
        default:
            return state
    }
}


export interface RefDataState {
    states: RefStatesState
    bodies: RefBodiesState
    makes: RefMakesState
    models: RefModelsState
    colors: RefColorsState
}

export const RefDataReducer = Redux.combineReducers({
    states,
    bodies,
    makes,
    models,
    colors,
})
