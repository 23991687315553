import * as React from "react";
import {Icon, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {DropzoneComponent} from "react-dropzone-component";

let fpStyle=require("react-dropzone-component/styles/filepicker.css");
let dropzoneStyle=require("dropzone/dist/min/dropzone.min.css");
export class PhotoUploadContainer extends React.Component<PhotoUploadContainerProps, PhotoUploadContainerState> {

    private dropZone: any;
    private processing: boolean;


    constructor(props: PhotoUploadContainerProps, context: any) {
        super(props, context);
        this.processing = false;
        this.state = {vehicleId: props.vehicleId, regionCode: props.regionCode};
    }

    render() {


        let uploadText = (<span/>);
        let componentConfig = {
            postUrl: 'NONE'
        };

        let eventHandlers = {
            addedfile: this.onAddedFile,
            init: this.dzInit
        }

        let djsConfig = {autoProcessQueue: false, addRemoveLinks: false, parallelUploads: 22}


        if (this.state.uploadMessage) {
            uploadText = (<div style={{
                fontSize: '20pt',
                color: 'red',
                marginLeft: '40%',
                marginTop: '20px'
            }} dangerouslySetInnerHTML={{__html: this.state.uploadMessage}}></div>)
        }
        return <Segment>
            <DropzoneComponent config={componentConfig}
                               djsConfig={djsConfig}
                               eventHandlers={eventHandlers}>
                <Icon name='camera' size="large"/>
            </DropzoneComponent>

            {uploadText}
        </Segment>
    }


    onAddedFile = (file: File) => {
        if (!this.processing) {
            this.processing = true
            fetch("/api/ppi/fileManager/getFileUploadUrl/" + this.props.regionCode + "/anonymous/10").then((response: Response) => {
                this.processing = false;
                return response.text()
            }).then((url: string) => {
                this.dropZone.options.url = url + "&imsVehicleId=" + this.props.vehicleId
                this.dropZone.processQueue()
            }).catch(() => {
                this.processing = false;
            })
        }

    };


    dzInit = (dzObject: any) => {
        this.dropZone = dzObject;
    };

}

interface PhotoUploadContainerProps {
    vehicleId: string | number
    regionCode: string
    onComplete: () => void
}

interface PhotoUploadContainerState {
    uploadMessage?: string
    vehicleId?: string | number
    regionCode?: string
}

const mapProperties = (state: PhotoUploadContainerState, ownProps: PhotoUploadContainerProps) => {
    let newProps = {
        vehicleId: state.vehicleId,
        regionCode: state.regionCode
    };
    return newProps;

};

const mapState = () => {

};

export default connect<PhotoUploadContainerProps, PhotoUploadContainerState, any>(mapProperties, mapState)(PhotoUploadContainer);