import * as React from "react";
import {CodeAndName, ModelDto, MakeDto, StateDto, ColorDto, BodyDto} from "../../model/dto/refdata";
import {Checkbox, Dropdown, Form} from "semantic-ui-react";

let styles = require("./resources/ppi.css");

interface PpiVehiclePanelProps {
    makes: MakeDto[]
    models: ModelDto[]
    colors: ColorDto[]
    states: StateDto[]
    bodies: BodyDto[]
    make?: MakeDto
    model?: ModelDto
    color?: ColorDto
    body?: BodyDto
    license?: string
    licenseState?: StateDto
    noVin?: boolean
    noPlate?: boolean
    nonStandardVin?: boolean
    vin?: string
    year?: string
    preferredStateCode?: string|number
    errorProps: String[]


    onChange: (state:PpiVehiclePanelState) => void
    onVinChange: (state:PpiVehiclePanelState) => void
}

export interface PpiVehiclePanelState {
    noPlate?: boolean
    noVin?: boolean
    nonStandardVin?: boolean
    makeSearch?: string
    modelSearch?: string
    licenseStateSearch?: string
    colorSearch?: string
    bodySearch?: string
}

export default class PpiVehiclePanel extends React.Component<PpiVehiclePanelProps, PpiVehiclePanelState> {
    constructor(props: PpiVehiclePanelProps) {
        super(props)
        this.state = {};
    }


    //This change handler uses a passed in name as the variable name in the state
    handleComboboxGenericChange = (propName:string, possibles:any) => {
        return (event:any, data:any) => {

            let rawValue = data.value;
            let value = possibles.find((obj: CodeAndName) => {
                return obj.code == rawValue
            });
            let stateUpdate:any = {};
            stateUpdate[propName] = value;
            this.props.onChange(stateUpdate)
        }
    };


    //This change handler uses the name from the input as the variable name in the state
    handleGenericChange = (event:any) => {
        let value = (event.target as HTMLInputElement).value;
        let stateUpdate:any = {};
        stateUpdate[(event.target as HTMLInputElement).name] = value;
        this.props.onChange(stateUpdate)
    };

    //This change handler uses the name from the input as the variable name in the state
    handleGenericChangeUppercase = (event:any) => {
        let value = (event.target as HTMLInputElement).value.toUpperCase();
        let stateUpdate:any = {};
        stateUpdate[(event.target as HTMLInputElement).name] = value;
        this.props.onChange(stateUpdate)
    };

    //This change handler uses the name from the input as the variable name in the state
    handleVinChange = (event:any) => {
        let value = (event.target as HTMLInputElement).value.toUpperCase();
        let stateUpdate:any = {};
        stateUpdate[(event.target as HTMLInputElement).name] = value;
        this.props.onVinChange(stateUpdate)
    };

    onNoVinToggle = () => {
        let stateUpdate = {};
        if (this.props.noVin) {
            stateUpdate = {
                vin: '',
                noVin: false
            };
        } else {
            stateUpdate = {
                vin: 'NO VIN',
                nonStandardVin: false,
                noVin: true
            };
        }
        this.props.onChange(stateUpdate)
    };
    onNonStandardVinToggle = () => {
        let stateUpdate = {
            nonStandardVin: !this.props.nonStandardVin
        };
        this.props.onChange(stateUpdate);
    };
    onNoPlateToggle = () => {
        let stateUpdate = {};
        if (this.props.noPlate) {
            stateUpdate = {
                license: '',
                licenseState: null,
                noPlate: false
            };
        } else {
            stateUpdate = {
                license: 'NO PLATE',
                licenseState: {code: '--', name: '--'},
                noPlate: true
            };
        }
        this.props.onChange(stateUpdate);
    }

    onSearchChange = (stateVariableName:any) => {
        return (newString:any) => {
            let stateUpdate:any = {};
            stateUpdate[stateVariableName] = newString;
            this.setState(stateUpdate);
        }
    }

    emptyOption = (<Dropdown.Item value=""/>)

    render() {
        let optionElement = (obj: CodeAndName) => {
            return ({value: obj.code, text: obj.name})
        };

        let makes = this.props.makes.map(optionElement);
        let models = (this.props.models) ? this.props.models.map(optionElement) : [];
        let colors = this.props.colors.map(optionElement);
        let bodies = this.props.bodies.map(optionElement);

        let optionElementState = (obj: CodeAndName) => {
            return ({value: obj.code, text: obj.code, key: `${obj.code}-${obj.name}`})
        };

        let licenseStates = this.props.states.map(optionElementState);
        if (this.props.preferredStateCode) {
            licenseStates = [optionElementState({
                code: this.props.preferredStateCode,
                name: ""
            })].concat(licenseStates)
        }
        return (
            <div>
                <Form>
                    <Form.Group>
                        <Form.Input width="3" disabled={this.props.noVin} placeholder="Vehicle Vin" name="vin"
                                    type="text"
                                    value={this.props.vin}
                                    error={this.props.errorProps.indexOf("vin") != -1}
                                    onChange={this.handleVinChange}/>
                    </Form.Group>
                    <Form.Field>
                        <Checkbox onClick={this.onNoVinToggle}
                                  checked={this.props.noVin} label="No VIN"/>
                    </Form.Field>
                    <Form.Field>
                        <Checkbox disabled={this.props.noVin} onClick={this.onNonStandardVinToggle}
                                  checked={this.props.nonStandardVin} label="Non Standard VIN"/>
                    </Form.Field>

                    <Form.Group>
                        <Form.Input width="3" disabled={this.props.noPlate} placeholder="Vehicle License" name="license"
                                    type="text"
                                    value={this.props.license}
                                    error={this.props.errorProps.indexOf("license") != -1}
                                    onChange={this.handleGenericChangeUppercase}/>
                        <Form.Field width="1">

                            <Dropdown
                                selection
                                search
                                className="narrowDropdown"
                                text={this.props.licenseState && this.props.licenseState.code}
                                onChange={this.handleComboboxGenericChange("licenseState",this.props.states)}
                                options={licenseStates}
                            >
                            </Dropdown>

                        </Form.Field>
                    </Form.Group>

                    <Form.Field>
                        <Checkbox onClick={this.onNoPlateToggle}
                                  checked={this.props.noPlate} label="No Plate"/>
                    </Form.Field>

                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Dropdown
                                openOnFocus={false}
                                selection
                                placeholder="Make"
                                text={this.props.make && this.props.make.name}
                                error={this.props.errorProps.indexOf("make") != -1}
                                onChange={this.handleComboboxGenericChange("make",this.props.makes)}
                                options={makes}
                                search
                                value={this.props.make && this.props.make.name}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Dropdown
                                openOnFocus={false}
                                selection
                                placeholder="Model"
                                text={this.props.model && this.props.model.name}
                                error={this.props.errorProps.indexOf("model") != -1}
                                onChange={this.handleComboboxGenericChange("model",this.props.models)}
                                options={models}
                                search
                                value={this.props.model && this.props.model.name}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Dropdown
                                openOnFocus={false}
                                selection
                                placeholder="Color"
                                error={this.props.errorProps.indexOf("color") != -1}
                                text={this.props.color && this.props.color.name}
                                onChange={this.handleComboboxGenericChange("color", this.props.colors)}
                                options={colors}
                                search
                            />
                        </Form.Field>
                        <Form.Field>
                            <Dropdown
                                openOnFocus={false}
                                selection
                                placeholder="Body"
                                error={this.props.errorProps.indexOf("body") != -1}
                                text={this.props.body && this.props.body.name}
                                onChange={this.handleComboboxGenericChange("body", this.props.bodies)}
                                options={bodies}
                                search
                                value={this.props.body && this.props.body.name}
                            />
                        </Form.Field>

                        <Form.Field>
                            <Form.Input placeholder="Vehicle Year" name="year" type="number" value={this.props.year}
                                        error={this.props.errorProps.indexOf("year") != -1}
                                        onChange={this.handleGenericChange}/>
                        </Form.Field>
                    </Form.Group>

                </Form>
            </div>
        )
    }
}