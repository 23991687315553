import React from 'react';
import './App.css';
import PpiContainer from "./ui/containers/PpiContainer";
import PpiEditContainer from "./ui/containers/PpiEditContainer";
import PpiCompleteContainer from "./ui/containers/PpiCompleteContainer";
import {store} from "./ui/config/configure";
import {HashRouter, Route, Switch} from "react-router-dom";
import {Provider} from 'react-redux';
import Favicon from "react-favicon";

export default class Root extends React.Component {

    render() {
        return (
            <Provider store={store}>
                <HashRouter>
                    <Favicon url="/fancyfavicon/favicon.ico"/>
                    <Switch>
                        <Route path='/result' render={() => <PpiCompleteContainer store={store}/>}/>
                        <Route exact path='/' render={() => <PpiContainer store={store}/>}/>
                        <Route path='/edit' render={() => <PpiEditContainer store={store}/>}/>
                    </Switch>
                </HashRouter>
            </Provider>
        )
    }
}




