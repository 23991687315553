import * as React from "react";
import {CodeAndName} from "../../model/dto/ppi";
import {RegionDto, StateDto} from "../../model/dto/refdata";
import {Checkbox, Confirm, Dropdown, Form} from "semantic-ui-react";
import ReactDatePicker from "react-datepicker";


var moment = require('moment');
const modernizr = require('./resources/modernizr-custom.js');

let styles = require("./resources/ppi.css");

interface PpiTowPanelProps {
    states: StateDto[]
    preferredStateCode: string | number


    searchingOrigin: boolean
    regions: RegionDto[]
    region?: RegionDto;
    date?: string
    time?: string
    timeUnparsed?: string
    referenceNumber?: string
    origin?: string
    originCity?: string
    originState?: StateDto
    destination?: string
    destinationCity?: string
    destinationState?: StateDto
    propertyName?: string
    requestedBy?: string
    lawAgency?: string
    repo?: boolean
    towCompanyRep?: string
    driver?: string
    driverId?: string
    notes?: string
    towCompanyName?: string
    vehicleOwner?: string
    lienHolder?: string
    lienHolderPhone?: string

    errorProps?: String[]

    onChange: (PpiTowPanelState?) => void
    onOriginChange: (PpiTowPanelState?) => void
    onTimeChange: (PpiTowPanelState?) => void
}

interface PpiTowPanelState {
    originStateSearchString?: string
    destinationStateSearchString?: string
    showWarning?: boolean
    pendingRegion?: RegionDto
}
export default class PpiTowPanel extends React.Component<PpiTowPanelProps, PpiTowPanelState> {
    constructor(props: PpiTowPanelProps) {
        super(props);
        this.state = {};
    }

    handleDestinationStateChange = (event, data) => {

        let rawValue = data.value;
        let value = this.props.states.find((obj: CodeAndName) => {
            return obj.code == rawValue
        });
        let stateUpdate = {destinationState: value};
        this.props.onChange(stateUpdate)
    }

    handleRegionChange = (event, data) => {

        let rawValue = data.value;
        let value = this.props.regions.find((obj: CodeAndName) => {
            return obj.code == rawValue
        });

        this.setState({showWarning: true, pendingRegion: value})

    };

    handleRegionConfirm = () => {

        let stateUpdate = {region: this.state.pendingRegion};
        this.props.onChange(stateUpdate)
        this.setState({showWarning: false, pendingRegion: undefined})
    }

    handleRegionCancel = () => {
        this.setState({showWarning: false, pendingRegion: undefined})
    }

    handleOriginChange = (event) => {
        let value = (event.target as HTMLInputElement).value;
        let stateUpdate = {origin: value};
        this.props.onOriginChange(stateUpdate)
    }

    handleOriginStateChange = (event, data) => {

        let rawValue = data.value;
        let value = this.props.states.find((obj: CodeAndName) => {
            return obj.code == rawValue
        });
        let stateUpdate = {originState: value};
        this.props.onOriginChange(stateUpdate)
    }

    handleOriginCityChange = (event) => {
        let value = (event.target as HTMLInputElement).value;
        let stateUpdate = {originCity: value};
        this.props.onOriginChange(stateUpdate)
    }


    //This change handler uses the name from the input as the variable name in the state
    handleGenericChange = (event) => {
        let value = (event.target as HTMLInputElement).value;
        let stateUpdate = {};
        stateUpdate[(event.target as HTMLInputElement).name] = value;
        this.props.onChange(stateUpdate)
    };


    //This change handler uses the id from the input as the variable name in the state
    handleTimeChange = (event) => {
        let value = (event.target as HTMLInputElement).value;
        let stateUpdate = {time: value};
        this.props.onTimeChange(stateUpdate)
    };

    //not used IF browser supports native date inputs
    handleDateChange = (date?) => {
        if (date) {
            let dateString = date.format("YYYY-MM-DD");
            let stateUpdate = {};
            stateUpdate['date'] = dateString;
            this.props.onChange(stateUpdate)
        } else {
            let stateUpdate = {};
            stateUpdate['date'] = null;
            this.props.onChange(stateUpdate)
        }

    }


    onRepoToggle = () => {
        let stateUpdate = {
            vehicleOwner: null,
            lienHolder: null,
            lienHolderPhone: null,
            repo: !this.props.repo
        };
        this.props.onChange(stateUpdate);
    };


    onSearchChange = (stateVariableName) => {
        return (newString) => {
            let stateUpdate = {};
            stateUpdate[stateVariableName] = newString;
            this.setState(stateUpdate);
        }
    };

    render() {
        let optionElementState = (obj: CodeAndName) => {
            return ({value: obj.code, text: obj.code, key: `${obj.code}-${obj.name}`})
        };

        let licenseStates = this.props.states.map(optionElementState);
        if (this.props.preferredStateCode) {
            licenseStates = [optionElementState({code: this.props.preferredStateCode, name: ""})].concat(licenseStates)
        }

        let repoFields = (<span/>);
        if (this.props.repo) {
            repoFields = (
                <Form.Group widths='equal'>
                    <Form.Input placeholder="Vehicle Owner" id="vehicleOwner" name="vehicleOwner" type="text"
                                value={this.props.vehicleOwner}
                                error={this.props.errorProps.indexOf("vehicleOwner") != -1}
                                onChange={this.handleGenericChange}/>
                    <Form.Input placeholder="Lien Holder" id="lienHolder" name="lienHolder" type="text"
                                value={this.props.lienHolder}
                                error={this.props.errorProps.indexOf("lienHolder") != -1}
                                onChange={this.handleGenericChange}/>
                    <Form.Input placeholder="Lien Holder Phone" id="lienHolderPhone" name="lienHolderPhone" type="text"
                                value={this.props.lienHolderPhone}
                                error={this.props.errorProps.indexOf("lienHolderPhone") != -1}
                                onChange={this.handleGenericChange}/>
                </Form.Group>);
        }
        let spinnerJsx = (<div/>);
        if (this.props.searchingOrigin) {
            spinnerJsx = (<img style={{height: "2.75em", width: "2.75em"}} src={"/spinner.svg"} alt="spinner"/>);
        }
        let googleJsx = (<img src={"/google_on_white.png"} alt="google"/>);
        let regionJsx = (<div/>);
        if (this.props.regions && this.props.regions.length > 0) {
            if (this.props.regions.length == 1) {
                regionJsx = (<div>Will be reported to the authorities in {this.props.regions[0].name}</div>);
            } else {
                let regionOptionsMapper = (obj: RegionDto) => {
                    return ({value: obj.code, text: obj.name})
                };

                let regionOptions = this.props.regions.map(regionOptionsMapper)

                regionJsx = (
                    <div>
                        <div>Will be reported to the authorities in</div>
                        <Dropdown
                            selection
                            openOnFocus={false}
                            placeholder="Law agency"
                            text={this.props.region && this.props.region.name}
                            onChange={this.handleRegionChange}
                            error={this.props.errorProps.indexOf("region") != -1}
                            options={regionOptions}
                        >
                        </Dropdown>
                    </div>);
            }
        }

        let dateJsx = (<ReactDatePicker placeholderText="Tow time" selected={moment(this.props.date, "YYYY-MM-DD")}
                                        onChange={this.handleDateChange}/>);
        if (window['Modernizr'].inputtypes.date) {
            dateJsx = (<Form.Input type="date" name="date" value={this.props.date} onChange={this.handleGenericChange}
                                   error={this.props.errorProps?.indexOf("date") != -1}/>)
        }

        let regionWarning = <span/>
        if (this.state.showWarning) {
            let text = ("It is your responsibility to submit your entry to the proper law enforcement agency and are about to submit your entry to _region." +
                " By doing so, you certify that the tow is within _region's jurisdiction. If the correct jurisdiction is not listed that Agency does not " +
                "utilize the system and you should not submit this entry.").replace(/_region/g, this.state?.pendingRegion?.name)
            regionWarning = <Confirm
                content={text}
                onCancel={this.handleRegionCancel}
                onConfirm={this.handleRegionConfirm}
                open
            />
        }

        return (
            <div>
                <Form>
                    <Form.Group widths='equal'>

                        <Form.Input placeholder="Tow Company" id="towCompanyName" name="towCompanyName" type="text"
                                    value={this.props.towCompanyName}
                                    error={this.props.errorProps?.indexOf("towCompanyName") != -1}
                                    onChange={this.handleGenericChange}/>


                        <Form.Input placeholder="Reference Number" id="referenceNumber" name="referenceNumber"
                                    type="text"
                                    value={this.props.referenceNumber}
                                    error={this.props.errorProps?.indexOf("referenceNumber") != -1}
                                    onChange={this.handleGenericChange}/>

                    </Form.Group>
                    <Form.Group>

                        {dateJsx}

                        <Form.Input placeholder="Time" id="time" name="time" type="time" value={this.props.timeUnparsed}
                                    error={this.props.errorProps?.indexOf("time") != -1}
                                    onChange={this.handleTimeChange}/>

                    </Form.Group>
                    <Form.Group>

                        <Form.Input width="5" placeholder="Origin" id="origin" name="origin" type="text"
                                    value={this.props.origin}
                                    error={this.props.errorProps.indexOf("origin") != -1}
                                    onChange={this.handleOriginChange}/>


                        <Form.Input width="5" placeholder="City" id="originCity" name="originCity" type="text"
                                    error={this.props.errorProps.indexOf("originCity") != -1}
                                    value={this.props.originCity}
                                    onChange={this.handleOriginCityChange}/>

                        <Form.Field>

                            <Dropdown
                                className="narrowDropdown"
                                openOnFocus={false}
                                selection
                                error={this.props.errorProps.indexOf("originState") != -1}
                                text={this.props.originState && this.props.originState.code}
                                onChange={this.handleOriginStateChange}
                                options={licenseStates}
                                search
                            >
                            </Dropdown>
                        </Form.Field>
                        {regionJsx}
                        {regionWarning}
                        {spinnerJsx}
                    </Form.Group>
                    {googleJsx}
                    <Form.Group>

                        <Form.Input width="5" placeholder="Destination" id="destination" name="destination" type="text"
                                    value={this.props.destination}
                                    error={this.props.errorProps.indexOf("destination") != -1}
                                    onChange={this.handleGenericChange}/>


                        <Form.Input width="5" placeholder="City" id="destinationCity" name="destinationCity" type="text"
                                    value={this.props.destinationCity}
                                    error={this.props.errorProps.indexOf("destinationCity") != -1}
                                    onChange={this.handleGenericChange}/>

                        <Form.Field>

                            <Dropdown
                                className="narrowDropdown"
                                selection
                                openOnFocus={false}
                                text={this.props.destinationState && this.props.destinationState.code}
                                onChange={this.handleDestinationStateChange}
                                error={this.props.errorProps.indexOf("destinationState") != -1}
                                options={licenseStates}
                                search
                            >

                            </Dropdown>

                        </Form.Field>
                    </Form.Group>
                    <Form.Input placeholder="Property Name" id="propertyName" name="propertyName" type="text"
                                value={this.props.propertyName}
                                error={this.props.errorProps.indexOf("propertyName") != -1}
                                onChange={this.handleGenericChange}/>
                    <Form.Input placeholder="Authorized/Requested By" id="requestedBy" name="requestedBy" type="text"
                                value={this.props.requestedBy}
                                error={this.props.errorProps.indexOf("requestedBy") != -1}
                                onChange={this.handleGenericChange}/>
                    <Form.Field>
                        <Checkbox onClick={this.onRepoToggle}
                                  checked={this.props.repo} label="Repossession"/>
                    </Form.Field>

                    {repoFields}

                    <Form.Group widths='equal'>
                        <Form.Input placeholder="Tow Company Rep" id="towCompanyRep" name="towCompanyRep" type="text"
                                    value={this.props.towCompanyRep}
                                    error={this.props.errorProps.indexOf("towCompanyRep") != -1}
                                    onChange={this.handleGenericChange}/>


                        <Form.Input placeholder="Driver" id="driver" name="driver" type="text" value={this.props.driver}
                                    error={this.props.errorProps.indexOf("driver") != -1}
                                    onChange={this.handleGenericChange}/>

                        <Form.Input placeholder="Driver Id / Phone number" id="driverId" name="driverId" type="text" value={this.props.driverId}
                                    error={this.props.errorProps.indexOf("driverId") != -1}
                                    onChange={this.handleGenericChange}/>
                    </Form.Group>

                    <textarea placeholder="Notes / Vehicle Contents" id="notes" name="notes" value={this.props.notes}
                              onChange={this.handleGenericChange}/>

                </Form>
            </div>
        )
    }
}