import * as React from "react";
import {Form, Button} from "semantic-ui-react";

interface PpiPhoneNumberPanelProps {
    onPhoneNumberChange: (string?) => void
    onLookupPushed: () => void
    phoneNumber?: string
    phoneNumberSearching?: boolean
}

interface PpiPhoneNumberPanelState {
}

export default class PpiPhoneNumberPanel extends React.Component<PpiPhoneNumberPanelProps, PpiPhoneNumberPanelState> {

    constructor(props: PpiPhoneNumberPanelProps) {
        super(props)
    }


    handlePhoneNumberChange = (event, data) => {
        let value = (event.target as HTMLInputElement).value
        this.props.onPhoneNumberChange(value);
    }

    handlePress = (e) => {
        if (e.key === 'Enter' && !this.isPhoneInvalid()) {
            this.props.onLookupPushed()
        }
    };


    preventSubmit(e) {
        e.preventDefault();
    }
    render() {
        let disabled = this.isPhoneInvalid();


        let spinnerJsx = (<div/>);
        if (this.props.phoneNumberSearching) {
            spinnerJsx = (<img style={{height:"2.75em", width:"2.75em"}} src={"/spinner.svg"}/>);
        }
        return (
            <Form onSubmit={this.preventSubmit}>

                <Form.Group inline>
                    <Form.Input placeholder="Phone Number" onChange={this.handlePhoneNumberChange}
                                onKeyPress={this.handlePress}
                                value={this.props.phoneNumber}
                                type="tel"/>
                    <Button tabIndex="-1" disabled={disabled} onClick={this.props.onLookupPushed}>Lookup</Button>
                    {spinnerJsx}
                </Form.Group>
            </Form>
        )
    }

    private isPhoneInvalid() {
        let phone = this.props.phoneNumber;
        let disabled = phone == null;
        if (phone) {
            phone = phone.replace(/[^\d]/g, "")
            disabled = phone.length != 10;
        }
        return disabled;
    }
}
