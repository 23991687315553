export class ServerError {
    constructor(public response:any) {
    }

}

export let serverErrorHandler = (response:any) => {
    if (!response.ok) {
        return Promise.reject(new ServerError(response))
    }
    return response
}
