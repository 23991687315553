export interface CodeAndName {
    code: string|number
    name: string
}

export class RegionDto implements CodeAndName {
    constructor(public id: number,
                public code: string,
                public name: string,
                public state: string,
                public serverTimeOffset: number) {

    }
}

export class StateDto implements CodeAndName {
    constructor(public code: string,
                public name: string) {

    }
}
export class MakeDto implements CodeAndName {
    constructor(public code: string,
                public name: string) {

    }
}

export interface ModelValues {
    values: ModelDto[]
}

export interface ModelMap {
    [index: string]: ModelValues
}

export class ModelDto implements CodeAndName {
    code: string

    constructor(public name: string,
                public makeId: number) {
        this.code = name
    }
}

export class ColorDto implements CodeAndName {
    code: string

    constructor(public name: string) {
        this.code = name
    }
}

export class BodyDto implements CodeAndName {
    code: string

    constructor(public name: string) {
        this.code = name
    }
}
