import * as React from "react";
import * as Redux from "react-redux";
import {connect} from "react-redux";
import {MakeDto, ModelDto, ModelMap, StateDto, CodeAndName, RegionDto, ColorDto, BodyDto} from "../../model/dto/refdata";
import {ApplicationState} from "../../model/reducers/index";
import PpiPhoneNumberPanel from "../components/PpiPhoneNumberPanel";
import PpiVehiclePanel from "../components/PpiVehiclePanel";
import PpiTowPanel from "../components/PpiTowPanel";
import {PpiVehicleDto, PpiTowDto, PpiSubmitDto, PpiResultDto, DecodedVinDto} from "../../model/dto/ppi";
import {ppiSubmit, companyFetch, originSearch, vinDecode} from "../../model/actions/PpiActions";
import {statesFetch, colorsFetch, modelsFetch, makesFetch, bodiesFetch} from "../../model/actions/RefDataActions";
import * as ReactRecaptcha from "react-recaptcha";
import {ServerError} from "../../model/actions/lib/ServerErrorHandler";
import MaskingMessageBox from "../components/MaskingMessageBox";
import {Segment, Header, Message, Button, Form} from "semantic-ui-react";
import {RouteComponentProps, withRouter} from "react-router-dom"
const moment = require('moment');

class PpiContainer extends React.Component<PpiContainerProps, PpiContainerState> {
    constructor(props) {
        super(props);
        this.state = {
            originSearchId: 0
        }
    }

    componentWillMount(): void {

        PpiContainer.loadIfMissing(this.props.makes, this.props.onMakesMissing);
        PpiContainer.loadIfMissing(this.props.colors, this.props.onColorsMissing);
        PpiContainer.loadIfMissing(this.props.states, this.props.onStatesMissing)
        PpiContainer.loadIfMissing(this.props.bodies, this.props.onBodiesMissing)

    }

    componentWillReceiveProps(props: PpiContainerProps): void {

        console.log("ppi container receiving props");
        let stateDto = this.props.states.find((obj: CodeAndName) => {
            return obj.code == props.preferredStateCode
        });

        let updatedOriginCity = (this.state.acceptProps) ? props.preferredCity : this.state.originCity;
        let updatedOriginState = (this.state.acceptProps) ? stateDto : this.state.originState;
        let updatedDestination = (this.state.acceptProps) ? props.destination : this.state.destination;
        let updatedDestinationCity = (this.state.acceptProps) ? props.preferredCity : this.state.destinationCity;
        let updatedDestinationState = (this.state.acceptProps ) ? stateDto : this.state.destinationState;
        let updatedTowCompanyName = (this.state.acceptProps) ? props.towCompanyName : this.state.towCompanyName;
        let updatedTowRep = (this.state.acceptProps) ? props.latestRep : this.state.towCompanyRep;
        let updatedLicenseState = (this.state.acceptProps && props.preferredStateCode) ? stateDto : this.state.licenseState;
        let displayNewCompanyMessage = (this.state.acceptProps) ? props.towCompanyName == "" : this.state.displayNewCompanyMessage;
        if (props.regions && props.regions.length == 1) {
            this.setState({region: props.regions[0]});
        }
        if(!props.regions || props.regions.length == 0) {
            this.setState({region: null});
        }

        this.setState({
            originCity: updatedOriginCity,
            originState: updatedOriginState,
            destination: updatedDestination,
            destinationCity: updatedDestinationCity,
            destinationState: updatedDestinationState,
            towCompanyName: updatedTowCompanyName,
            towCompanyRep: updatedTowRep,
            licenseState: updatedLicenseState,
            displayNewCompanyMessage: displayNewCompanyMessage,
            date: moment().format("YYYY-MM-DD")
        });
    }

    onCompanyMessagePushed = () => {
        this.setState({displayNewCompanyMessage: false});
    };

    render() {

        let [state, errorProperties, errorMessages] = this.validateForm();
        let errorsExist = state == "error";
        let errors = errorMessages.map((msg, i) => {
            return (<li key={i}>{msg}</li>)
        })
        let serverInfoText = (<span/>);
        let serverErrorText = (<span/>);
        if (this.state.serverError && this.state.serverError.response) {
            //known error
            if (this.state.serverError.response.headers.has("error")) {
                serverErrorText = (
                    <div className="ui error message"
                         dangerouslySetInnerHTML={{__html: this.state.serverError.response.headers.get("error")}}></div>)
            } else {
                //unknown error
                serverErrorText = (
                    <div className="ui error message">There was a problem with the request. Please call our partner support at 855-807-9336</div>)
            }
        }
        if (this.state.serverInfoMessage) {
            serverInfoText = (<div dangerouslySetInnerHTML={{__html:this.state.serverInfoMessage}}></div>)
        }
        let companyMessage = (<span/>);
        if (this.state.displayNewCompanyMessage) {
            companyMessage = <MaskingMessageBox
                message="We did not find a company matching this phone number.<br/> Please enter company information"
                onButtonPress={this.onCompanyMessagePushed}/>
        }
        let errorMessage = (<span/>);
        if (errorsExist) {
            errorMessage = <Message
                error
                content={errors}
            />;
        }
        let lowerPanels = (<span/>);
        if (this.props.towCompanyName != null) {
            lowerPanels = this.getLowerPanels(errorProperties, errorMessage, serverErrorText, errorsExist);
        } else {
            lowerPanels = serverErrorText;
        }
        let upperPanels = (<span/>);
        if (this.props.towCompanyName == null) {
            upperPanels = (<div>
                <Header style={{marginLeft: '3em'}} size="small">OR</Header>
                <Button tabIndex="-1" className="paddedButton" onClick={this.edit}>Edit an earlier entry</Button>
            </div>);
        }
        return (<div className="ui container">
            <Segment>
                <PpiPhoneNumberPanel onLookupPushed={this.onPhoneLookupPushed}
                                     onPhoneNumberChange={this.onPhoneNumberChange}
                                     phoneNumber={this.state.phoneNumber}
                                     phoneNumberSearching={this.state.phoneNumberSearching}/>

                {upperPanels}
            </Segment>
            {lowerPanels}
            {serverInfoText}

            {companyMessage}
        </div>)
    }

    private getLowerPanels(errorProperties: string[], errorMessage: any, serverErrorText: any, errorsExist: boolean) {
        return (<div>
            <Segment>
                <Header size="small">Vehicle Information</Header>
                <PpiVehiclePanel
                    colors={this.props.colors}
                    bodies={this.props.bodies}
                    makes={this.props.makes}
                    models={this.models()}
                    preferredStateCode={this.props.preferredStateCode}
                    states={this.props.states}
                    onChange={this.onVehicleChange}
                    onVinChange={this.onVinChange}
                    errorProps={errorProperties}
                    {...this.state}
                />
            </Segment>
            <Segment>
                <Header size="small">Tow Information</Header>
                <PpiTowPanel onChange={this.onTowChange}
                             states={this.props.states}
                             preferredStateCode={this.props.preferredStateCode}
                             onOriginChange={this.onOriginChange}
                             searchingOrigin={this.props.originSearch}
                             regions={this.props.regions}
                             onTimeChange={this.onTimeChange}
                             errorProps={errorProperties}
                             {...this.state}/>

            </Segment>

            {errorMessage}
            <div id="recaptchaTest" />
            <ReactRecaptcha
                elementID="recaptchaTest"
                sitekey="6LcxGtoSAAAAAPMQoSDbQSgkDEDtmJ52AnmpuDlF"
                verifyCallback={this.verifyCaptcha}
                render="explicit"
                onloadCallback={()=>{}}
                onExpireCallback={this.captchaExpire}
            />
            {serverErrorText}
            <Button tabIndex="-1" className="paddedButton" disabled={errorsExist} onClick={this.onRequestTowClicked}>Submit</Button>
        </div>);
    }

    static isValidVin(vin: string): boolean {
        if (vin.length !== 17) return false;
        return PpiContainer.getCheckDigit(vin) === vin[8];
    };

    parseTime = (timeString) => {
        return moment(timeString.replace(/[^\daAMmPp]/g, ""), ["hhmma", "HHmm", "hmma"], true);
    }


    static transliterate(c): number {
        return '0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ'.indexOf(c) % 10;
    }

    static getCheckDigit(vin): string {
        let map = '0123456789X';
        let weights = '8765432X098765432';
        let sum = 0;
        for (let i = 0; i < 17; ++i)
            sum += PpiContainer.transliterate(vin[i]) * map.indexOf(weights[i]);
        return map[sum % 11];
    }


    validateForm(): [string, string[], string[]] {
        let errors = [];
        let errorProperty = [];


        if (!this.state.noVin && (!this.state.vin || (!this.state.nonStandardVin && !PpiContainer.isValidVin(this.state.vin)))) {
            errors.push("Please enter VIN");
            errorProperty.push("vin");
        }


        if (!this.state.license || this.state.license.trim().length == 0) {
            errors.push("Please enter License")
            errorProperty.push("license");
        }


        if (!this.state.make) {
            errors.push("Please enter Make")
            errorProperty.push("make");
        }


        if (!this.state.model) {
            errors.push("Please enter Model")
            errorProperty.push("model");
        }

        if (!this.state.color) {
            errors.push("Please enter Color")
            errorProperty.push("color");
        }

        if (this.state.year && (!/^[0-9]{4}$/.test(this.state.year) || !(Number(this.state.year) > 1900 && this.state.year <= (moment().year() + 1)))) {
            errors.push("Please enter valid year")
            errorProperty.push("year");
        }


        if (!this.state.date || this.state.date.trim().length == 0) {
            errors.push("Please enter Tow Date")
            errorProperty.push("date");
        }

        if (!this.state.time) {
            errors.push("Please enter Tow Time")
            errorProperty.push("time");
        } else {
            if (!moment(this.parseTime(this.state.time)).isValid()) {
                errors.push("Please enter Tow Time")
                errorProperty.push("time");
            }
        }


        if (!this.state.origin || this.state.origin.trim().length == 0) {
            errors.push("Please enter Origin Address")
            errorProperty.push("origin");
        }


        if (!this.state.originCity || this.state.originCity.trim().length == 0) {
            errors.push("Please enter Origin City")
            errorProperty.push("originCity");
        }


        if (!this.state.originState) {
            errors.push("Please enter Origin State")
            errorProperty.push("originState");
        }
        if (!this.state.region) {
            errors.push("Please check that origin is in a supported area")
            errorProperty.push("region");
        }

        if (!this.state.destination || this.state.destination.trim().length == 0) {
            errors.push("Please enter Destination Address")
            errorProperty.push("destination");
        }


        if (!this.state.destinationCity || this.state.destinationCity.trim().length == 0) {
            errors.push("Please enter Destination City")
            errorProperty.push("destinationCity");
        }


        if (!this.state.destinationState) {
            errors.push("Please enter Destination State")
            errorProperty.push("destinationState");
        }
        if (!this.state.towCompanyRep || this.state.towCompanyRep.trim().length == 0) {
            errors.push("Please enter Tow Company Representative")
            errorProperty.push("towCompanyRep");
        }
        if (!this.state.towCompanyName || this.state.towCompanyName.trim().length == 0) {
            errors.push("Please enter Tow Company Name")
            errorProperty.push("towCompanyName");
        }
        if (!this.state.driverId || this.state.driverId.trim().length == 0) {
            errors.push("Please enter Driver Id")
            errorProperty.push("driverId");
        }
        if (!this.state.driver || this.state.driver.trim().length == 0) {
            errors.push("Please enter Driver")
            errorProperty.push("driver");
        }

        if (this.state.repo && (!this.state.vehicleOwner || this.state.vehicleOwner.trim().length == 0)) {
            errors.push("Please enter Vehicle Owner")
            errorProperty.push("vehicleOwner");
        }


        if (!this.state.repo && (!this.state.propertyName || this.state.propertyName.trim().length == 0)) {
            errors.push("Please enter Property Name")
            errorProperty.push("propertyName");
        }

        if (!this.state.repo && (!this.state.requestedBy || this.state.requestedBy.trim().length == 0)) {
            errors.push("Please enter Requested By")
            errorProperty.push("requestedBy");
        }

        if (this.state.repo && (!this.state.lienHolder || this.state.lienHolder.trim().length == 0)) {
            errors.push("Please enter Lien Holder")
            errorProperty.push("lienHolder");
        }

        if (this.state.repo && (!this.state.lienHolderPhone || this.state.lienHolderPhone.replace(/[^\d]/g, "").length != 10)) {
            errors.push("Please enter Lien Holder Phone")
            errorProperty.push("lienHolderPhone");
        }

        if (!this.state.captchaKey) {
            errors.push("Please complete captcha")
        }

        return [errors.length > 0 ? "error" : null, errorProperty, errors]
    }


    verifyCaptcha = (event) => {
        this.setState({captchaKey: event});
    };


    captchaExpire = () => {
        this.setState({captchaKey: null});
    }

    onPhoneNumberChange = (value) => {
        this.setState({phoneNumber: value});
    };

    onPhoneLookupPushed = () => {
        this.setState({serverInfoMessage: null, phoneNumberSearching: true});
        let preSuccessCallback = () => {
            this.setState({acceptProps: true})
        };
        let postSuccessCallback = () => {
            this.setState({acceptProps: false, phoneNumberSearching: false})
        };
        let errorCallback = (error) => {
            this.setState({serverError: error, acceptProps: false, phoneNumberSearching: false})
        };
        this.props.onPhoneNumberLookup(this.state.phoneNumber, preSuccessCallback, postSuccessCallback, errorCallback);
    };

    onVehicleChange = (ppiVehicleDto?: PpiVehicleDto) => {
        this.setState(ppiVehicleDto)
    };

    onTowChange = (ppiTowDto?: PpiTowDto) => {
        this.setState(ppiTowDto)
    };

    onOriginChange = (ppiTowDto?: PpiTowDto) => {
        let newState = this.bypassContext(ppiTowDto);
        this.waitUntilTimeout(() => {
            this.props.searchForOrigin(newState.origin, newState.originCity, newState.originState, (error) => {
                this.setState({serverError: error})
            });
        }, 1000)


    };

    waitUntilTimeout = (onTimeout: ()=>any, timeoutMillis: number) => {
        let id = this.state.originSearchId + 1;
        this.setState({
            originSearchId: id
        });
        setTimeout(() => {
            if (this.state.originSearchId == id) {
                onTimeout();
            } else {
                console.log("bypassed:" + id)
            }
        }, timeoutMillis);
    };

    bypassContext = (state) => {
        this.setState(state);
        return Object.assign(this.state, state);
    };

    onVinChange = (ppiVehicleDto?: PpiVehicleDto) => {
        this.setState(ppiVehicleDto)
        if (PpiContainer.isValidVin(ppiVehicleDto.vin)) {
            this.setState({serverInfoMessage: null, vinDecoding: true});
            let callback = (result: DecodedVinDto) => {
                this.setState({make: result.make, model: result.model, year: result.year, body: result.bodyDto })
            };
            let errorCallback = (error) => {
                this.setState({serverError: error, acceptProps: false, vinDecoding: false})
            };
            this.props.onVinDecode(ppiVehicleDto.vin, callback, errorCallback);
        }

    };


    onTimeChange = (ppiTowDto?: PpiTowDto) => {
        let time = this.parseTime(ppiTowDto.time);
        this.setState({
            time: time.format("HHmm"),
            timeUnparsed: ppiTowDto.time
        });
    };

    onRequestTowClicked = () => {
        this.setState((prevState, props) => {
            if (!prevState.submitting) {
                return {
                    towCompanySearchComplete: true,
                    submitting: true,
                    duplicateSubmit: false
                }
            } else {
                return {
                    towCompanySearchComplete: true,
                    duplicateSubmit: true
                }
            }

        }, () => {
            if (this.state.duplicateSubmit) {
                return
            }

            let [status, _] = this.validateForm()
            if (status != null) {
                return;
            }

            let submit = new PpiSubmitDto();
            submit.vehicle = this.state as PpiVehicleDto;
            submit.tow = this.state as PpiTowDto;

            let regionCode = this.state.region.code;

            this.props.onSubmitPpi(regionCode, submit, (result: PpiResultDto) => {
                this.props.history.push('/result')
            }, (error) => {
                //this.resetCaptcha()
                this.setState({serverError: error, submitting: false, duplicateSubmit: false})
            });
        });
    };

    edit = () => {
        this.props.history.push('/edit');
    };

    clearState = (serverInfoMessage: string) => {
        //grecaptcha.reset will only reset the first captcha that was created
        //we need to tell it that each captcha is the first captcha
        window['___grecaptcha_cfg'].count = 0
        let newState = {};
        Object.assign(newState, this.state);
        for (let propertyName in this.state) {
            newState[propertyName] = null;
        }
        newState['phoneNumber'] = this.state.phoneNumber;
        newState['serverInfoMessage'] = serverInfoMessage;
        this.setState(newState);
    };

    private models(): ModelDto[] {
        let make = this.state.make;
        if (make) {
            let modelValues = this.props.models[make.code];
            if (modelValues) {
                return modelValues.values
            } else {
                this.props.onModelsMissing(make.code)
            }
        }
        return null
    }


    private static loadIfMissing<T>(values: T[], callback: () => void): void {
        if (!values || values.length == 0) {
            callback()
        }
    }

}

interface PpiContainerValues {
    // states
    states: StateDto[]

    // make, model, body
    makes: MakeDto[]
    models: ModelMap
    colors: ColorDto[]
    bodies: BodyDto[]
    regions: RegionDto[]

    originSearch?: boolean

    towCompanyName?: string
    latestRep?: string
    destination?: string
    preferredCity?: string
    preferredStateCode?: string

}

interface PpiContainerDispatch {
    onPhoneNumberLookup: (phoneNumber: string, preSuccessCallback: () => void, postSuccessCallback: () => void, errorCallback: (error) => void) => void
    searchForOrigin: (origin: string, originCity: string, stateDto: StateDto, errorCallback: (error) => void) => void
    onVinDecode: (vin: string, callback: (result: DecodedVinDto) => void, errorCallback: (error) => void) => void
    onMakesMissing: () => void
    onModelsMissing: (string) => void
    onColorsMissing: () => void
    onStatesMissing: () => void
    onBodiesMissing: () => void
    onSubmitPpi: (regionCode, submitDto, onComplete: (result: PpiResultDto) => void, errorCallback: (error) => void) => void
}

interface PpiContainerProps extends PpiContainerValues, PpiContainerDispatch, RouteComponentProps<any> {
}

interface PpiContainerState extends PpiVehicleDto, PpiTowDto {

    make?: MakeDto
    model?: ModelDto
    color?: ColorDto
    body?: BodyDto
    license?: string
    licenseState?: StateDto
    noVin?: boolean
    noPlate?: boolean
    nonStandardVin?: boolean
    vin?: string
    year?: string

    date?: string
    time?: string
    timeUnparsed?: string
    referenceNumber?: string
    origin?: string
    originCity?: string
    originState?: StateDto
    destination?: string
    destinationCity?: string
    destinationState?: StateDto
    propertyName?: string
    lawAgency?: string
    repo?: boolean
    towCompanyRep?: string
    driver?: string
    notes?: string


    captchaKey?: string
    phoneNumber?: string
    phoneNumberSearching?: boolean
    vinDecoding?: boolean

    towCompanyName?: string

    serverError?: ServerError
    serverInfoMessage?: string
    acceptProps?: boolean

    submitting?: boolean
    duplicateSubmit?: boolean

    displayNewCompanyMessage?: boolean

    originSearchId?: number
}


interface PpiContainerOwnProps {
}

const mapStateToProps = (state: ApplicationState, ownProps: PpiContainerOwnProps) => {
    let newProps = {
        makes: state.refdata.makes.values,
        models: state.refdata.models,
        colors: state.refdata.colors.values,
        states: state.refdata.states.values,
        bodies: state.refdata.bodies.values,
        regions: null
    };

    let ppiUpdate = {
        towCompanyName: null,
        latestRep: null,
        preferredStateCode: null,
        preferredCity: null,
        destination: null,
        phoneNumberFake: null,
    }
    if (state.ppiCompany != null && state.ppiCompany.values != null && state.ppiCompany.values.companyName != null) {
        let ppi = state.ppiCompany.values;
        ppiUpdate = {
            towCompanyName: ppi.companyName,
            latestRep: ppi.latestRep,
            preferredStateCode: ppi.preferredStateCode,
            preferredCity: ppi.preferredCity,
            destination: ppi.destination,
            phoneNumberFake: ppi.phoneNumber
        }
    }
    Object.assign(newProps, ppiUpdate);
    let regionsUpdate = {
        regions: null,
        originSearch: false
    }
    if (state.ppiRegions != null) {
        if (state.ppiRegions.values != null) {
            regionsUpdate = {
                regions: state.ppiRegions.values,
                originSearch: state.ppiRegions.status.pending
            }
        } else {
            regionsUpdate = {
                regions: null,
                originSearch: state.ppiRegions.status.pending
            }
        }
    }
    Object.assign(newProps, regionsUpdate);
    return newProps;

};


const mapDispatchToProps = (dispatch: Redux.Dispatch, ownProps: PpiContainerOwnProps) => {
    return {

        onPhoneNumberLookup: (phoneNumber: string, preSuccessCallback: ()=>void, postSuccessCallback: ()=>void, errorCallback: (error) => void) => {
            dispatch(companyFetch(phoneNumber, preSuccessCallback, postSuccessCallback, errorCallback))
        },

        onVinDecode: (vin: string, callback: (result: DecodedVinDto)=>void, errorCallback: (error) => void) => {
            dispatch(vinDecode(vin, callback, errorCallback))
        },
        onMakesMissing: () => {
            dispatch(makesFetch())
        },
        onModelsMissing: (makeCode: string) => {
            dispatch(modelsFetch(makeCode))
        },
        onColorsMissing: () => {
            dispatch(colorsFetch())
        },
        onStatesMissing: () => {
            dispatch(statesFetch())
        },
        onBodiesMissing: () => {
            dispatch(bodiesFetch())
        },
        searchForOrigin: (origin: string, originCity: string, originState: StateDto, errorCallback: (error) => void) => {
            dispatch(originSearch(origin, originCity, originState, errorCallback))
        },
        onSubmitPpi: (regionCode: string, submittal: PpiSubmitDto, onComplete: (result?: PpiResultDto) => void, errorCallback?: (error) => void) => {
            dispatch(ppiSubmit(regionCode, submittal, (result: PpiResultDto) => {
                if (onComplete) {
                    onComplete(result)
                }
            }, (error) => {
                if (errorCallback) {
                    errorCallback(error);
                }
            }))

        }
    }
};

export default withRouter<any, any>(connect<PpiContainerValues, PpiContainerDispatch,any>(mapStateToProps, mapDispatchToProps)(PpiContainer as any))
