import * as Redux from "redux";
import * as ReactRouterRedux from "react-router-redux";
import {RefDataReducer, RefDataState} from "./RefDataReducers";
import {
    PpiCompanyReducer,
    PpiCompanyState,
    OriginSearchState,
    OriginSearchReducer,
    PpiResultState,
    PpiResultReducer
} from "./PpiReducer";

export interface ApplicationState {
    refdata: RefDataState
    ppiCompany: PpiCompanyState
    ppiRegions: OriginSearchState
    ppiResult: PpiResultState
}

const rootReducer = Redux.combineReducers({
    routing: ReactRouterRedux.routerReducer,
    refdata: RefDataReducer,
    ppiCompany: PpiCompanyReducer,
    ppiRegions: OriginSearchReducer,
    ppiResult: PpiResultReducer
})

export default rootReducer
