import {PpiCompanyDto, PpiFileSubmitDto, PpiResultDto} from "../dto/ppi";
import {serverErrorHandler} from "./lib/ServerErrorHandler";
import {Dispatch} from "redux";

export class FileRegisterBefore {
    type: "FileRegisterBefore" = "FileRegisterBefore"

    constructor(public attachmentIds: number[]) {
    }
}

export class FileRegisterAfter {
    type: "FileRegisterAfter" = "FileRegisterAfter"

    constructor(public attachmentIds: number[]) {
    }
}

export class FileRegisterError {
    type: "FileRegisterError" = "FileRegisterError"

    constructor(public error: any) {
    }
}

export type FileRegisterAction = FileRegisterBefore | FileRegisterAfter | FileRegisterError

export function registerFiles(ppiVehicleId: number, attachmentIds: number[]) {

    return function (dispatch: Dispatch<any>) {
        dispatch(new FileRegisterBefore(attachmentIds));
        fetch(`/api/ppi/registerFile`, {
            method: "post",
            body: JSON.stringify(new PpiFileSubmitDto(ppiVehicleId, attachmentIds)),
            headers: {"Content-Type": "application/json"}
        }).then(serverErrorHandler).then(
            result => {
                return new FileRegisterAfter(attachmentIds);
            }
        )
            .then(action => {
                dispatch(action);
            })
            .catch((error) => {
                dispatch(new FileRegisterError(error))
            })
    }
}
