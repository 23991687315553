import * as React from "react";
import {Button} from "semantic-ui-react";


interface MaskingMessageBoxProps {
    message: string
    onButtonPress: () => void
}

interface MaskingMessageBoxState {
}
export default class MaskingMessageBox extends React.Component<MaskingMessageBoxProps, MaskingMessageBoxState> {
    constructor(props: MaskingMessageBoxProps) {
        super(props);
        this.state = {};
    }


    render() {

        return <div>
            <div
                style={{zIndex:2,position: 'fixed', width:"100%", height:"100%", top:"0", left:"0", opacity:0.1 , backgroundColor:"black"}}/>
            <div
                className="maskingMessageBox">
                <span dangerouslySetInnerHTML={{__html: this.props.message}}/>
                <br/>
                <Button tabIndex="-1" onClick={this.props.onButtonPress}>Ok</Button>
            </div>
        </div>
    }
}