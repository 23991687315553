import * as React from "react";
import * as Redux from "react-redux";
import {connect} from "react-redux";
import {ApplicationState} from "../../model/reducers";
import {ServerError} from "../../model/actions/lib/ServerErrorHandler";
import {Button, Header, Segment} from "semantic-ui-react";
import {DropzoneComponent} from 'react-dropzone-component';
import {registerFiles} from "../../model/actions/PpiFileActions";
import {RouteComponentProps, withRouter} from "react-router";

let fpStyle=require("react-dropzone-component/styles/filepicker.css");
let dropzoneStyle=require("dropzone/dist/min/dropzone.min.css");

let styles = require("../components/resources/ppi.css");

class PpiCompleteContainer extends React.Component<PpiCompleteContainerProps, PpiCompleteContainerState> {
    private dropZone: any;


    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        //make file upload disappear once its timed out
        let interval = window.setInterval(() => this.setState({time: Date.now()}), 10000);
        this.setState({intervalId: interval});
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }


    render() {
        let serverInfoText = (<span/>);
        let serverErrorText = (<span/>);
        let uploadText = (<span/>);
        if (this.props.serverError && this.props.serverError.response && this.props.serverError.response.headers.has("error")) {
            serverErrorText = (
                <div className="ui error message"
                     dangerouslySetInnerHTML={{__html: this.props.serverError.response.headers.get("error")}}></div>)
        }
        if (this.props.serverInfoMessage) {
            serverInfoText = (<div dangerouslySetInnerHTML={{__html: this.props.serverInfoMessage}}></div>)
        }

        let showFileUpload = this.props.fileSubmitEndtime.setMinutes(this.props.fileSubmitEndtime.getMinutes()+4, this.props.fileSubmitEndtime.getSeconds()+45);

        if (showFileUpload) {
            if (this.state.uploadMessage) {
                uploadText = (<div style={{
                    fontSize: '20pt',
                    color: 'red',
                    marginLeft: '40%',
                    marginTop: '20px'
                }} dangerouslySetInnerHTML={{__html: this.state.uploadMessage}}></div>)
            }
        } else {
            uploadText = (<div style={{
                fontSize: '16pt',
                marginLeft: '20%',
                marginTop: '20px'
            }}>We are no longer accepting files for this vehicle.</div>)
        }

        let componentConfig = {
            iconFiletypes: ['.jpg', '.png', '.gif'],
            showFiletypeIcon: true,
            postUrl: this.props.fmUrl
        };

        let djsConfig = {
            addRemoveLinks: false,
        };
        let eventHandlers = {
            success: this.onSuccess,
            init: this.dzInit
        }

        return (<div className="ui container">
            <link href={styles} rel="stylesheet"/>
            <div>
                <Segment>
                    <Header size="small">Confirmation</Header>
                    {serverInfoText}
                    {showFileUpload && <DropzoneComponent config={componentConfig}
                                                          djsConfig={djsConfig}
                                                          eventHandlers={eventHandlers}>
                        <p>Drop a file here, or click to select files to upload.</p>
                        <p>Upload will expire in 5 minutes.</p>
                    </DropzoneComponent>
                    }

                    {uploadText}
                </Segment>

                <Button tabIndex="-1" className="paddedButton" onClick={this.onBack}>Back</Button>
                {serverErrorText}
            </div>
        </div>)
    }


    onDrop(acceptedFiles: File[], rejectedFiles: File[]) {

        this.props.onUpload(acceptedFiles, this.props.ppiVehicleId, this.props.fmUrl);

    }

    onSuccess = (file: File, response: string) => {
        console.log(response);
        if (/(\d,?)+/.test(response)) {
            this.props.onSuccess(this.props.ppiVehicleId, response.split(",").map(s => Number(s)));
        } else {
            this.dropZone.removeFile(file);
            //set error message
            this.setState({uploadMessage: ""});
        }
    };


    dzInit = (dzObject: any) => {
        this.dropZone = dzObject;
    };

    onBack = () => {
        this.props.history.push('/');
    };

    clearState = (serverInfoMessage: string) => {
        let newState = {};
        Object.assign(newState, this.state);
        for (let propertyName in this.state) {
            newState[propertyName] = null;
        }
        newState['serverInfoMessage'] = serverInfoMessage;
        this.setState(newState);
    };

}

interface PpiCompleteContainerValues {
    serverError?: ServerError
    serverInfoMessage?: string
    ppiVehicleId: number
    fmUrl: string
    fileSubmitEndtime: Date
}

interface PpiCompleteContainerDispatch {
    onUpload: (files: File[], ppiVehicleId: number, fmUrl: string) => void
    onBack: () => void
    onSuccess: (ppiVehicleId: number, attachmentIds: number[]) => void
}

interface PpiCompleteContainerProps extends PpiCompleteContainerValues, PpiCompleteContainerDispatch, RouteComponentProps<any>  {
}

interface PpiCompleteContainerState {
    uploadMessage?: string
    //this exists so the file upload will disappear after it times out
    time?: number
    intervalId?: number;
}


const mapStateToProps = (state: ApplicationState, ownProps: PpiCompleteContainerProps) => {
    let result = state.ppiResult.values;
    let completeDate = new Date()
    completeDate.setMinutes(completeDate.getMinutes()+4)
    completeDate.setSeconds(completeDate.getSeconds()+40)
    let submitMessage = "Successfully submitted tow: " + result.referenceNumber + " at " + result.submitDate + ".";
    let newProps = {
        serverInfoMessage: submitMessage,
        fmUrl: state.ppiResult.values.fmUrl,
        ppiVehicleId: state.ppiResult.values.ppiVehicleId,
        fileSubmitEndtime: completeDate
    };
    return newProps;

};

const mapDispatchToProps = (dispatch: Redux.Dispatch, ownProps: PpiCompleteContainerProps) => {
    return {
        onSuccess: (ppiVehicleId: number, attachmentIds: number[]) => {
            dispatch(registerFiles(ppiVehicleId, attachmentIds));
        }
    }
};


export default withRouter<any, any>(connect<PpiCompleteContainerValues, PpiCompleteContainerDispatch, any>(mapStateToProps, mapDispatchToProps)(PpiCompleteContainer as any))
