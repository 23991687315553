import * as Redux from "redux";
import thunk from "redux-thunk";
import * as createLogger from "redux-logger";
import {routerMiddleware} from "react-router-redux";
import rootReducer from "../../model/reducers/index";
import {ourHistory} from "./configure";



const typedToPlain = (store: any) => (next: any) => (action: any) => {
    next(Object.assign({}, action))
}

export default function configureStore() {

    return Redux.compose(
        Redux.applyMiddleware(
            thunk,
            typedToPlain,
            routerMiddleware(ourHistory),

        ))(Redux.createStore)(rootReducer)
}
