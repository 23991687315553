import * as React from "react";
import * as Redux from "react-redux";
import {connect} from "react-redux";
import {BodyDto, CodeAndName, StateDto} from "../../model/dto/refdata";
import {ApplicationState} from "../../model/reducers/index";
import {PpiResultDto, PpiVehicleDispositionInfoDto, PpiVehicleDispositionRequestDto} from "../../model/dto/ppi";
import {bodiesFetch, statesFetch} from "../../model/actions/RefDataActions";
import {ServerError} from "../../model/actions/lib/ServerErrorHandler";
import {Button, Checkbox, Dropdown, Form, Header, Message, Radio, Segment} from "semantic-ui-react";
import {PpiEditSubmitDto} from "../../model/dto/ppiEdit";
import {ppiEditSubmit, ppiGetDispositionInfo} from "../../model/actions/PpiEditActions";
import {PhotoUploadContainer} from "./PhotoUploadContainer";
import moment from 'moment';
import {RouteComponentProps, withRouter} from "react-router";


require('react-datepicker/dist/react-datepicker.css');

const react_router_redux_1 = require('react-router-redux');
const STATE_SELECTOR_VIN = 'updatePlateOrVin';
const STATE_SELECTOR_PHOTOS = 'addPhotos'
const STATE_SELECTOR_RECORD_SALE = 'recordSale'

let styles = require("../components/resources/ppi.css");

class PpiEditContainer extends React.Component<PpiEditContainerProps, PpiEditContainerState> {
    constructor(props) {
        super(props);
        this.state = {showVehicleDisposition: false, selectorState: STATE_SELECTOR_VIN}
    }

    componentWillMount(): void {

        PpiEditContainer.loadIfMissing(this.props.states, this.props.onStatesMissing)
        PpiEditContainer.loadIfMissing(this.props.bodies, this.props.onBodiesMissing)

    }

    preventSubmit(e) {
        e.preventDefault();
    }

    handleSoldDateChange = (date?) => {
        if (date) {
            let value = (date.target as HTMLInputElement).value.toUpperCase();
            let stateUpdate = {};
            stateUpdate[(date.target as HTMLInputElement).name] = value;
            stateUpdate['soldDate'] = value;
            this.setState({soldDate: value});
        } else {
            let stateUpdate = {};
            stateUpdate['soldDate'] = null;
            this.setState({soldDate: null});
        }
    }

    handleSoldTimeChange = (time?) => {
        if (time) {
            let value = (time.target as HTMLInputElement).value.toUpperCase();
            let stateUpdate = {};
            stateUpdate[(time.target as HTMLInputElement).name] = value;
            stateUpdate['soldTime'] = value;
            this.setState({soldTime: value});
        } else {
            let stateUpdate = {};
            stateUpdate['soldTime'] = null;
            this.setState({soldTime: null});
        }
    }

    handleReleasedDateChange = (date?) => {
        if (date) {
            let value = (date.target as HTMLInputElement).value.toUpperCase();
            let stateUpdate = {};
            stateUpdate[(date.target as HTMLInputElement).name] = value;
            stateUpdate['releasedDate'] = value;
            this.setState({releasedDate: value});
        } else {
            let stateUpdate = {};
            stateUpdate['releasedDate'] = null;
            this.setState({releasedDate: null});
        }
    }

    handleReleasedTimeChange = (time?) => {
        if (time) {
            let value = (time.target as HTMLInputElement).value.toUpperCase();
            let stateUpdate = {};
            stateUpdate[(time.target as HTMLInputElement).name] = value;
            stateUpdate['releasedTime'] = value;
            this.setState({releasedTime: value});
        } else {
            let stateUpdate = {};
            stateUpdate['releasedTime'] = null;
            this.setState({releasedTime: null});
        }
    }

    setSelectorState = (event, {value}) => {
        this.setState({selectorState: value});
    }

    getDispositionInfo = () => {
        let request = new PpiVehicleDispositionRequestDto();
        request.license = this.state.oldLicense;
        request.vin = this.state.oldVin;
        request.referenceNum = this.state.referenceNum;
        this.props.onPpiGetDispositionInfo(request, (result: PpiVehicleDispositionInfoDto) => {
            this.setState({regionCode: result.regionCode, vehicleId: result.vehicleId, showVehicleDisposition: result.showVehicleDisposition})
        }, (error) => {
            this.setState({serverError: error, submitting: false, showVehicleDisposition: false})
        });
    }

    render() {
        let [state, errorProperties, errorMessages] = this.validateForm();
        let errorsExist = state == "error";
        let errors = errorMessages.map((msg, i) => {
            return (<li key={i}>{msg}</li>)
        })
        let serverInfoText = (<span/>);
        let serverErrorText = (<span/>);
        if (this.state.serverError && this.state.serverError.response && this.state.serverError.response.headers.has("error")) {
            serverErrorText = (
                <div className="ui error message"
                     dangerouslySetInnerHTML={{__html: this.state.serverError.response.headers.get("error")}}></div>)
        }
        if (this.state.serverInfoMessage) {
            serverInfoText = (<div dangerouslySetInnerHTML={{__html: this.state.serverInfoMessage}}></div>)
        }
        let errorMessage = (<span/>);
        if (errorsExist) {
            errorMessage = <Message
                error
                content={errors}
            />;
        }
        let optionElementState = (obj: CodeAndName) => {
            return ({value: obj.code, text: obj.code})
        };


        if (!this.props.states) {
            return (<div className="ui container">LOADING</div>);
        }

        if (this.state.serverInfoMessage) {
            return (<div className="ui container">
                {this.state.serverInfoMessage}

                <Button tabIndex="-1" className="paddedButton" onClick={this.onBack}>Back</Button>
            </div>);
        }

        let licenseStates = this.props.states.map(optionElementState);

        let updateVehicleInfoSection = <div/>;

        if (!this.state.soldSateSelected && !this.state.releaseDateSelected && this.state.selectorState == STATE_SELECTOR_VIN) {
            updateVehicleInfoSection = <div>
                <Header size="small">Updated Information</Header>
                <Form.Group>
                    <Form.Input width="4" placeholder="New License" onChange={this.onInputChange}
                                error={errorProperties.indexOf("new") != -1}
                                value={this.state.newLicense} name="newLicense"/>

                    <Form.Field width="1">

                        <Dropdown
                            selection
                            search
                            className="narrowDropdown"
                            text={this.state.newLicenseState && this.state.newLicenseState.code}
                            onChange={this.handleComboboxGenericChange("newLicenseState", this.props.states)}
                            options={licenseStates}
                        >
                        </Dropdown>

                    </Form.Field>
                </Form.Group>
                <span style={{marginLeft: '3em'}}>AND / OR</span>
                <div style={{height: '1em'}}> &nbsp;</div>
                <Form.Group>
                    <Form.Input width="4" placeholder="New Vin" onChange={this.onInputChange}
                                error={errorProperties.indexOf("vin") != -1 || errorProperties.indexOf("new") != -1}
                                value={this.state.newVin} name="newVin"/>

                    <Form.Field>
                        <Checkbox onClick={this.onNonStandardVinToggle}
                                  checked={this.state.nonStandardVin} label="Non Standard VIN"/>
                    </Form.Field>
                </Form.Group>
            </div>;
        }


        let datesSection = <div/>;
        if (this.state.showVehicleDisposition && this.state.selectorState == STATE_SELECTOR_RECORD_SALE) {
            datesSection = <div>
                <Header size="small">Vehicle Disposition</Header>
                <Form.Group>
                    <Form.Field width={5}>
                        <Checkbox onClick={this.onSoldDateSelected} label="Vehicle Sold" checked={this.state.soldSateSelected}/>
                    </Form.Field>
                    <Form.Field width={5}>
                        <input type="date" id="soldDate" name="soldDate" onChange={this.handleSoldDateChange} value={this.state.soldDate}/>
                    </Form.Field>
                    <Form.Field width={3}>
                        <input type="time" id="soldTime" name="soldTime" onChange={this.handleSoldTimeChange} value={this.state.soldTime}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={5}>
                        <Checkbox onClick={this.onReleasedDateSelected} label="Vehicle Released" checked={this.state.releaseDateSelected}/>
                    </Form.Field>
                    <Form.Field width={5}>
                        <input type="date" id="releasedDate" name="releasedDate" onChange={this.handleReleasedDateChange} value={this.state.releasedDate}/>
                    </Form.Field>
                    <Form.Field width={3}>
                        <input type="time" id="releasedTime" name="releasedTime" onChange={this.handleReleasedTimeChange} value={this.state.releasedTime}/>
                    </Form.Field>
                </Form.Group>
            </div>

        }

        let photoUploadSection = <div/>;
        if (this.state.vehicleId && this.state.selectorState == STATE_SELECTOR_PHOTOS) {
            photoUploadSection = <div>
                <PhotoUploadContainer vehicleId={this.state.vehicleId} regionCode={this.state.regionCode} onComplete={this.onPhotoSubmit}/>
            </div>;
        }


        return (<div className="ui container">
            <link href={styles} rel="stylesheet"/>
            <div>
                <Segment>
                    <Header size="small">Edit Information</Header>
                    <Segment>
                        <Form>
                            <Form.Field>
                                <Radio
                                    style={{marginLeft: '3em'}}
                                    label='Update plate or VIN'
                                    name='radioGroup'
                                    value='updatePlateOrVin'
                                    checked={this.state.selectorState === STATE_SELECTOR_VIN}
                                    onChange={this.setSelectorState}
                                />
                                <Radio
                                    style={{marginLeft: '3em'}}
                                    label='Add Photos'
                                    name='radioGroup'
                                    value='addPhotos'
                                    checked={this.state.selectorState === STATE_SELECTOR_PHOTOS}
                                    onChange={this.setSelectorState}
                                />
                                <Radio
                                    style={{marginLeft: '3em'}}
                                    label='Sale/Release'
                                    name='radioGroup'
                                    value='recordSale'
                                    checked={this.state.selectorState === STATE_SELECTOR_RECORD_SALE}
                                    onChange={this.setSelectorState}
                                />
                            </Form.Field>
                        </Form>
                    </Segment>

                    <Form onSubmit={this.preventSubmit}>

                        <Form.Group>
                            <Form.Input placeholder="Reference Number (P1234)" width="4"
                                        onChange={this.onInputChange}
                                        onBlur={this.getDispositionInfo}
                                        error={errorProperties.indexOf("referenceNum") != -1}
                                        value={this.state.referenceNum} name="referenceNum"/>
                        </Form.Group>

                        <Header size="small">Previous Information</Header>
                        <Form.Group>
                            <Form.Input width="4" placeholder="Old License"
                                        onChange={this.onInputChange}
                                        onBlur={this.getDispositionInfo}
                                        error={errorProperties.indexOf("old") != -1}
                                        value={this.state.oldLicense} name="oldLicense"/>
                        </Form.Group>

                        <span style={{marginLeft: '3em'}}>OR</span>
                        <div style={{height: '1em'}}> &nbsp;</div>
                        <Form.Group>
                            <Form.Input width="4" placeholder="Old Vin"
                                        onChange={this.onInputChange}
                                        onBlur={this.getDispositionInfo}
                                        error={errorProperties.indexOf("old") != -1}
                                        value={this.state.oldVin} name="oldVin"/>

                        </Form.Group>


                        {updateVehicleInfoSection}
                        <br/>
                        <br/>

                        {datesSection}

                    </Form>
                    {photoUploadSection}

                </Segment>

                {errorMessage}
                {serverErrorText}
                <Button tabIndex="-1" className="paddedButton" disabled={errorsExist} onClick={this.onRequestTowClicked}>Submit</Button>
            </div>
        </div>)
    }

    onPhotoSubmit = () => {
        window.alert('onPhotoSubmit called');
    };

    onNonStandardVinToggle = () => {
        let stateUpdate = {
            nonStandardVin: !this.state.nonStandardVin
        };
        this.setState(stateUpdate);
    };

    onSoldDateSelected = () => {
        this.setState({soldSateSelected: true});
        this.setState({releaseDateSelected: false})
        this.setState({releasedDate: null, releasedTime: null})
        this.setState({soldDate: moment().format('YYYY-MM-DD')})
        this.setState({soldTime: moment().format('HH:mm')})
    }

    onReleasedDateSelected = () => {
        this.setState({soldSateSelected: false});
        this.setState({releaseDateSelected: true})
        this.setState({soldDate: null, soldTime: null})
        this.setState({releasedDate: moment().format('YYYY-MM-DD')})
        this.setState({releasedTime: moment().format('HH:mm')})
    }


    static isValidVin(vin: string): boolean {
        if (vin.length !== 17) return false;
        return PpiEditContainer.getCheckDigit(vin) === vin[8];
    };


    static transliterate(c): number {
        return '0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ'.indexOf(c) % 10;
    }

    static getCheckDigit(vin): string {
        let map = '0123456789X';
        let weights = '8765432X098765432';
        let sum = 0;
        for (let i = 0; i < 17; ++i)
            sum += PpiEditContainer.transliterate(vin[i]) * map.indexOf(weights[i]);
        return map[sum % 11];
    }


    validateForm(): [string, string[], string[]] {
        let errors = [];
        let errorProperty = [];


        if (!this.state.referenceNum) {
            errors.push("Please enter reference number (P123...)");
            errorProperty.push("referenceNum");
        }

        if (!this.state.soldSateSelected && !this.state.releaseDateSelected && this.state.selectorState === STATE_SELECTOR_VIN) {
            if (!this.state.newVin && (!this.state.newLicense || this.state.newLicense.trim().length == 0)) {
                errors.push("Please enter new VIN and/or license");
                errorProperty.push("new");
            }
        }

        if (!this.state.oldVin && (!this.state.oldLicense || this.state.oldLicense.trim().length == 0)) {
            errors.push("Please enter old VIN or license");
            errorProperty.push("old");
        }

        if (this.state.newVin && (!this.state.nonStandardVin && !PpiEditContainer.isValidVin(this.state.newVin))) {
            errors.push("Invalid vin");
            errorProperty.push("vin");
        }


        return [errors.length > 0 ? "error" : null, errorProperty, errors]
    }

    onInputChange = (event) => {
        let value = (event.target as HTMLInputElement).value.toUpperCase();
        let stateUpdate = {};
        stateUpdate[(event.target as HTMLInputElement).name] = value;
        this.setState(stateUpdate);
    };
    //This change handler uses a passed in name as the variable name in the state
    handleComboboxGenericChange = (propName, possibles) => {
        return (event, data) => {

            let rawValue = data.value;
            let value = possibles.find((obj: CodeAndName) => {
                return obj.code == rawValue
            });
            let stateUpdate = {};
            stateUpdate[propName] = value;
            this.setState(stateUpdate);
        }
    };


    onRequestTowClicked = () => {
        this.setState((prevState, props) => {
            if (!prevState.submitting) {
                return {
                    towCompanySearchComplete: true,
                    submitting: true,
                    duplicateSubmit: false
                }
            } else {
                return {
                    towCompanySearchComplete: true,
                    duplicateSubmit: true
                }
            }

        }, () => {
            if (this.state.duplicateSubmit) {
                return
            }

            let [status, _] = this.validateForm()
            if (status != null) {
                return;
            }


            this.props.onSubmitPpiEdit(this.state, (result: PpiResultDto) => {
                this.clearState("Successfully submitted edit: " + result.referenceNumber + " at " + result.submitDate + ".");
            }, (error) => {
                this.setState({serverError: error, submitting: false, duplicateSubmit: false})
            });
        });
    };


    onBack = () => {
        this.props.history.push('/');
    };

    clearState = (serverInfoMessage: string) => {
        let newState = {};
        Object.assign(newState, this.state);
        for (let propertyName in this.state) {
            newState[propertyName] = null;
        }
        newState['serverInfoMessage'] = serverInfoMessage;
        this.setState(newState);
    };

    private static loadIfMissing<T>(values: T[], callback: () => void): void {
        if (!values || values.length == 0) {
            callback()
        }
    }

}

interface PpiEditContainerValues {

    // states
    states: StateDto[]
    bodies: BodyDto[]
}

interface PpiEditContainerDispatch {
    onStatesMissing: () => void
    onBodiesMissing: () => void
    onSubmitPpiEdit: (submitDto, onComplete: (result: PpiResultDto) => void, errorCallback: (error) => void) => void
    onPpiGetDispositionInfo: (submitDto, onComplete: (result: PpiVehicleDispositionInfoDto) => void, errorCallback: (error) => void) => void
}

interface PpiEditContainerProps extends PpiEditContainerValues, PpiEditContainerDispatch, RouteComponentProps<any> {
    soldDate?: string
    releasedDate?: string
    showDates: boolean
    regionCode?: string
    vehicleId?: string | number
}

interface PpiEditContainerState extends PpiEditSubmitDto {
    oldLicense?: string
    oldVin?: string

    referenceNum?: string
    newVin?: string
    nonStandardVin?: boolean
    newLicense?: string
    newLicenseState?: StateDto

    serverError?: ServerError
    serverInfoMessage?: string

    submitting?: boolean
    duplicateSubmit?: boolean

    soldSateSelected?: boolean
    releaseDateSelected?: boolean
    time?: string

    showVehicleDisposition?: boolean

    vehicleId?: number
    regionCode?: string
    selectorState?: string

}


interface PpiEditContainerOwnProps {
}

const mapStateToProps = (state: ApplicationState, ownProps: PpiEditContainerOwnProps) => {
    let newProps = {
        states: state.refdata.states.values,
        soldDate: moment().format('YYYY-MM-DD'),
        releasedDate: moment().format('YYYY-MM-DD'),
    };
    return newProps;

};

const mapDispatchToProps = (dispatch: Redux.Dispatch, ownProps: PpiEditContainerOwnProps) => {
    return {

        onStatesMissing: () => {
            dispatch(statesFetch())
        },
        onBodiesMissing: () => {
            dispatch(bodiesFetch())
        },
        onSubmitPpiEdit: (submittal: PpiEditSubmitDto, onComplete: (result?: PpiResultDto) => void, errorCallback?: (error) => void) => {
            dispatch(ppiEditSubmit(submittal, (result: PpiResultDto) => {
                if (onComplete) {
                    onComplete(result)
                }
            }, (error) => {
                if (errorCallback) {
                    errorCallback(error);
                }
            }))

        },
        onPpiGetDispositionInfo: (submittal: PpiVehicleDispositionRequestDto, onComplete: (results?: PpiVehicleDispositionInfoDto) => void, errorCallback?: (error) => void) => {
            dispatch(ppiGetDispositionInfo(submittal, (result: PpiVehicleDispositionInfoDto) => {
                if (onComplete) {
                    onComplete(result)
                }
            }, (error) => {
                if (errorCallback) {
                    errorCallback(error);
                }
            }))

        },
    }
};


export default withRouter<any, any>(connect<PpiEditContainerValues, PpiEditContainerDispatch, any>(mapStateToProps, mapDispatchToProps)(PpiEditContainer as any))
