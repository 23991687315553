import "isomorphic-fetch";
import {serverErrorHandler} from "./lib/ServerErrorHandler";
import {Dispatch} from "redux";
import {PpiEditSubmitDto} from "../dto/ppiEdit";
import {PpiResultDto, PpiVehicleDispositionInfoDto, PpiVehicleDispositionRequestDto} from "../dto/ppi";


export class PpiEditSubmitBefore {
    type: "PpiEditSubmitBefore" = "PpiEditSubmitBefore"

    constructor(public PpiEditSubmit: PpiEditSubmitDto) {
    }
}

export class PpiEditSubmitAfter {
    type: "PpiEditSubmitAfter" = "PpiEditSubmitAfter"

    constructor(public PpiEditSubmit: PpiEditSubmitDto, public result: PpiResultDto) {
    }
}

export class PpiVehicleDispositionBefore {
    type: "PpiVehicleDispositionBefore" = "PpiVehicleDispositionBefore"

    constructor(public PpiDispositionInfoRequest: PpiVehicleDispositionRequestDto) {
    }
}

export class PpiVehicleDispositionAfter {
    type: "PpiVehicleDispositionAfter" = "PpiVehicleDispositionAfter"

    constructor(public PpiDispositionInfo: PpiVehicleDispositionRequestDto, public result: PpiVehicleDispositionInfoDto) {
    }
}

export class PpiEditSubmitError {
    type: "PpiEditSubmitError" = "PpiEditSubmitError"

    constructor(public PpiEditSubmit: PpiEditSubmitDto, public error: any) {
    }
}

export type PpiEditSubmitAction = PpiEditSubmitBefore | PpiEditSubmitAfter | PpiEditSubmitError | PpiVehicleDispositionAfter | PpiVehicleDispositionBefore


export function ppiEditSubmit(ppiEditSubmit: PpiEditSubmitDto, callback?: (result: PpiResultDto) => void, errorCallback?: (error: any) => void) {

    return function (dispatch: Dispatch<any>) {

        dispatch(new PpiEditSubmitBefore(ppiEditSubmit))

        return fetch(`/api/ppi/ppiEditSubmit`, {
            method: "post",
            body: JSON.stringify(ppiEditSubmit),
            headers: {"Content-Type": "application/json"}
        })
            .then(serverErrorHandler)
            .then(response => response.json())
            .then(json => new PpiEditSubmitAfter(ppiEditSubmit, json))
            .then(action => {
                dispatch(action);
                if (callback) callback(action.result)
            })
            .catch((error) => {
                if(errorCallback) {
                    errorCallback(error)
                }
                dispatch(new PpiEditSubmitError(ppiEditSubmit, error))
            })

    }
}

export function ppiGetDispositionInfo(request: PpiVehicleDispositionRequestDto, callback?: (result: PpiVehicleDispositionInfoDto) => void, errorCallback?: (error: any) => void) {
    return function (dispatch: Dispatch<any>) {
        dispatch(new PpiVehicleDispositionBefore(request));

        return fetch(`/api/ppi/showVehicleDisposition`, {
            method: "post",
            body: JSON.stringify(request),
            headers: {"Content-Type": "application/json"}
        })
            .then(serverErrorHandler)
            .then(response => response.json())
            .then(json => new PpiVehicleDispositionAfter(request, json))
            .then(action => {
                dispatch(action);
                if (callback) callback(action.result)
            })
            .catch((error) => {
                if(errorCallback) {
                    errorCallback(error)
                }
                console.log(error)
            });
    }
}