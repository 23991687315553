import "isomorphic-fetch";
import {serverErrorHandler} from "./lib/ServerErrorHandler";
import {PpiCompanyDto, PpiSubmitDto, PpiResultDto, DecodedVinDto} from "../dto/ppi";
import {StateDto, RegionDto} from "../dto/refdata";
import {Dispatch} from "redux";
import moment, {Moment} from 'moment';



export class CompanyFetchBefore {
    type: "CompanyFetchBefore" = "CompanyFetchBefore"

    constructor(public phoneNumber: string) {
    }
}

export class CompanyFetchAfter {
    type: "CompanyFetchAfter" = "CompanyFetchAfter"

    constructor(public company: PpiCompanyDto) {
    }
}

export class CompanyFetchError {
    type: "CompanyFetchError" = "CompanyFetchError"

    constructor(public error: any) {
    }
}

export type CompanyFetchAction = CompanyFetchBefore | CompanyFetchAfter | CompanyFetchError

export function companyFetch(phoneNumber: string, preSuccessCallback?: ()=>void, postSuccessCallback?: ()=>void, errorCallback?: (error: any) => void) {

    return function (dispatch: Dispatch<any>) {

        dispatch(new CompanyFetchBefore(phoneNumber))

        return fetch(`/api/ppi/companyLookup/${phoneNumber.replace(/[^\d]/g, "")}`)
            .then(serverErrorHandler)
            .then(response => response.json())
            .then(json => new CompanyFetchAfter(json))
            .then(action => {
                if (preSuccessCallback) preSuccessCallback()
                dispatch(action);
                if (postSuccessCallback) postSuccessCallback()
            })
            .catch((error) => {
                errorCallback(error)
                dispatch(new CompanyFetchError(error))
            })

    }

}


export class OriginSearchBefore {
    type: "OriginSearchBefore" = "OriginSearchBefore"

    constructor(public origin: string, public originCity: string, public originState: StateDto) {
    }
}

export class OriginSearchAfter {
    type: "OriginSearchAfter" = "OriginSearchAfter"

    constructor(public origin: string, public originCity: string, public originState: StateDto, public result: RegionDto[]) {
    }
}

export class OriginSearchError {
    type: "OriginSearchError" = "OriginSearchError"

    constructor(public origin: string, public originCity: string, public originState: StateDto, public error: any) {
    }
}


export type OriginSearchAction = OriginSearchBefore | OriginSearchAfter | OriginSearchError

export type OriginSearchOrPpiSubmit = OriginSearchAction | PpiSubmitAction

export function originSearch(origin: string, originCity: string, originState: StateDto, errorCallback?: (error: any) => void) {

    return function (dispatch: Dispatch<any>) {

        if(origin && originCity && originState) {

            dispatch(new OriginSearchBefore(origin, originCity, originState))

            return fetch(`/api/ppi/geocode/${origin}/${originCity}/${originState.code}`)
                .then(serverErrorHandler)
                .then(response => response.json())
                .then(json => dispatch(new OriginSearchAfter(origin, originCity, originState, json)))
                .catch((error) => {
                    errorCallback(error)
                    dispatch(new OriginSearchError(origin, originCity, originState, error))
                })
        }


    }

}

export class PpiSubmitBefore {
    type: "PpiSubmitBefore" = "PpiSubmitBefore"

    constructor(public regionCode: string, public PpiSubmit: PpiSubmitDto) {
    }
}

export class PpiSubmitAfter {
    type: "PpiSubmitAfter" = "PpiSubmitAfter"

    constructor(public regionCode: string, public PpiSubmit: PpiSubmitDto, public result: PpiResultDto, public submitTime: Moment) {
    }
}

export class PpiSubmitError {
    type: "PpiSubmitError" = "PpiSubmitError"

    constructor(public regionCode: string, public PpiSubmit: PpiSubmitDto, public error: any) {
    }
}

export type PpiSubmitAction = PpiSubmitBefore | PpiSubmitAfter | PpiSubmitError

export type PpiSubmitOrCompanyFetchAction = PpiSubmitAction | CompanyFetchAction

export function ppiSubmit(regionCode: string, ppiSubmit: PpiSubmitDto, callback?: (result: PpiResultDto) => void, errorCallback?: (error: any) => void) {

    return function (dispatch: Dispatch<any>) {

        dispatch(new PpiSubmitBefore(regionCode, ppiSubmit))

        return fetch(`/api/ppi/ppiSubmit`, {
            method: "post",
            body: JSON.stringify(ppiSubmit),
            headers: {"Content-Type": "application/json"}
        })
            .then(serverErrorHandler)
            .then(response => response.json())
            .then(json => new PpiSubmitAfter(regionCode, ppiSubmit, json, moment()))
            .then(action => {
                dispatch(action);
                if (callback) callback(action.result)
            })
            .catch((error) => {
                errorCallback(error)
                dispatch(new PpiSubmitError(regionCode, ppiSubmit, error))
            })

    }

}
export class VinDecodeBefore {
    type: "VinDecodeBefore" = "VinDecodeBefore"

    constructor(public vin: string) {
    }
}

export class VinDecodeAfter {
    type: "VinDecodeAfter" = "VinDecodeAfter"

    constructor(public vin: string, public result: DecodedVinDto) {
    }
}

export class VinDecodeError {
    type: "VinDecodeError" = "VinDecodeError"

    constructor(public vin: string, public error: any) {
    }
}

export function vinDecode(vin: string, callback?: (result: DecodedVinDto) => void, errorCallback?: (error: any) => void) {

    return function (dispatch: Dispatch<any>) {

        dispatch(new VinDecodeBefore(vin))

        return fetch(`/api/ppi/decodeVin/${vin}`)
            .then(serverErrorHandler)
            .then(response => response.json())
            .then(json => {  callback(json); return json;})
            .then(json => dispatch(new VinDecodeAfter(vin, json)))
            .catch((error) => {
                errorCallback(error)
                dispatch(new VinDecodeError(vin, error))
            })
    }

}