import * as Redux from "redux";
import {OperationStatus} from "./lib/OperationStatus";
import {PpiCompanyDto, PpiResultDto} from "../dto/ppi";
import {
    CompanyFetchAction,
    CompanyFetchAfter,
    OriginSearchAction,
    OriginSearchAfter,
    PpiSubmitOrCompanyFetchAction,
    OriginSearchOrPpiSubmit, PpiSubmitAfter, PpiSubmitAction
} from "../actions/PpiActions";
import {RegionDto} from "../dto/refdata";
import {Moment} from "moment";


export interface PpiCompanyStatusState extends OperationStatus {
}

export interface PpiCompanyState {
    status: PpiCompanyStatusState
    values: PpiCompanyDto
}

export const CompanyFetchStatusReducer = (state: PpiCompanyStatusState = {
    pending: false,
    error: false
}, action: CompanyFetchAction): PpiCompanyStatusState => {
    switch (action.type) {
        case "CompanyFetchBefore":
            return {pending: true, error: false}

        case "CompanyFetchAfter":
            return {pending: false, error: false}

        case "CompanyFetchError":
            return {pending: false, error: true}

        default:
            return state
    }
}

export const PpiCompanyFetchReducer = (state: PpiCompanyDto = {}, action: PpiSubmitOrCompanyFetchAction): PpiCompanyDto|null => {
    switch (action.type) {
        case "CompanyFetchAfter": {
            let typedAction = action as CompanyFetchAfter // TODO: remove when intellij works correctly

            let newState = Object.assign({}, state)
            newState = typedAction.company

            return newState
        }
        case "PpiSubmitAfter": {
            return null
        }
        default:
            return state

    }
}

export interface OriginSearchStatusState extends OperationStatus {
}

export interface OriginSearchState {
    status: PpiCompanyStatusState
    values: RegionDto[]
}

export const OriginSearchStatusReducer = (state: OriginSearchStatusState = {
    pending: false,
    error: false
}, action: OriginSearchAction): OriginSearchStatusState => {
    switch (action.type) {
        case "OriginSearchBefore":
            return {pending: true, error: false};

        case "OriginSearchAfter":
            return {pending: false, error: false};

        case "OriginSearchError":
            return {pending: false, error: true};

        default:
            return state
    }
}


export const OriginSearchValuesReducer = (state: any = {}, action: OriginSearchOrPpiSubmit): RegionDto[]|null => {
    switch (action.type) {
        case "OriginSearchAfter": {
            let typedAction = action as OriginSearchAfter // TODO: remove when intellij works correctly

            return typedAction.result
        }
        case "PpiSubmitAfter": {
            return null;
        }
        default:
            return state

    }
}


export interface PpiResultStatusState extends OperationStatus {
}

export interface PpiResultState {
    status: PpiResultStatusState
    values: PpiResultDto
    submitTime: string
}

export const PpiResultStatusReducer = (state: PpiResultStatusState = {
    pending: false,
    error: false
}, action: PpiSubmitAction): PpiResultStatusState => {
    switch (action.type) {
        case "PpiSubmitBefore":
            return {pending: true, error: false};

        case "PpiSubmitAfter":
            return {pending: false, error: false};

        case "PpiSubmitError":
            return {pending: false, error: true};

        default:
            return state
    }
}


export const PpiResultValuesReducer = (state: any = {}, action: PpiSubmitAfter): PpiResultDto => {
    switch (action.type) {
        case "PpiSubmitAfter": {
            let typedAction = action as PpiSubmitAfter // TODO: remove when intellij works correctly

            return typedAction.result
        }
        default:
            return state

    }
}


export const PpiResultDateReducer = (state: any = {}, action: PpiSubmitAfter): Moment => {
    switch (action.type) {
        case "PpiSubmitAfter": {
            let typedAction = action as PpiSubmitAfter // TODO: remove when intellij works correctly

            return typedAction.submitTime
        }
        default:
            return state

    }
}

export const PpiCompanyReducer = Redux.combineReducers({
    status: CompanyFetchStatusReducer,
    values: PpiCompanyFetchReducer
})


export const PpiResultReducer = Redux.combineReducers({
    status: PpiResultStatusReducer,
    values: PpiResultValuesReducer,
    fileSubmitEndtime: PpiResultDateReducer
})

export const OriginSearchReducer = Redux.combineReducers({
    status: OriginSearchStatusReducer,
    values: OriginSearchValuesReducer
})
